import React from "react"
import { Link } from "gatsby"

import { RedText } from "src/components/styled"

const FaqLink = () => (
  <RedText>
    Une question ? La réponse est sûrement ici : <Link to="/faq/">questions fréquentes</Link>
  </RedText>
)

export default FaqLink
