import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { WithContext, Course } from "schema-dts"

import { GatsbyPage, IWorkshop } from "src/types"
import Layout from "src/layout"
import Instagram from "src/components/Instagram"
import Image from "src/components/Image"
import {
  Section,
  Subtitle,
  Title,
  Description,
  Features,
  FeatCaption,
  Feature,
  SectionTitle,
  Text,
  InfosContainer,
  Infos,
  InfosImage,
  InfosContent,
  InfosTitle,
  InfosText,
  Content,
  ImageCaption,
  InternalCta,
} from "src/components/styled"
import LevelIcon from "src/svg/icon/Level"
import TimeIcon from "src/svg/icon/Time"
import ParticipantIcon from "src/svg/icon/Participant"
import MoneyIcon from "src/svg/icon/Money"
import { EMAIL_ADDRESS, INSTAGRAM, SIZES, BREAKPOINTS } from "src/constants"
import BuilderIcon from "src/svg/icon/Builder"
import Gallery from "src/components/Gallery"
import { Splash, Parallax } from "src/components/Parallax"
import RichText from "src/components/RichText"
import FaqLink from "src/components/FaqLink"
import { scrollToRef } from "src/helpers/window"
import { formatMoney } from "src/helpers/text"

const SubtitleWithIcon = styled(Subtitle)`
  svg {
    vertical-align: -15px;
    width: 70px;
    height: 70px;
  }
  @media (max-width: ${BREAKPOINTS.mobile}px) {
    display: flex;
    flex-direction: column-reverse;
    span {
      margin: -25px -5px 10px;
    }
    svg {
      width: 50px;
      height: 50px;
    }
  }
`
const WorkshopTitle = styled(SectionTitle)`
  margin-bottom: -10px;
`
const Models = styled.h4<{ margin?: boolean }>`
  font-size: ${SIZES.medium};
  margin: 2em 0 ${(props): string => (props.margin ? "1em" : "0")};
`
const Center = styled.div`
  margin: 40px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  a {
    margin: 20px 0;
  }
  @media (min-width: ${BREAKPOINTS.mobile}px) {
    margin: 60px 0;
    a:first-child {
      margin-right: 60px;
    }
  }
`

const CtaButtons = () => (
  <Center>
    <InternalCta to="/offrir/">
      <BuilderIcon />
      <span>
        Offrir
        <br />
        un atelier
      </span>
    </InternalCta>
    <InternalCta to="/reserver/">
      <BuilderIcon />
      <span>
        Réserver
        <br />
        un atelier
      </span>
    </InternalCta>
  </Center>
)

const WorkshopTemplate: GatsbyPage = ({ data, pageContext: { slug, tag } }) => {
  const workshop = data.workshop as IWorkshop
  const {
    picture,
    photo,
    mobilePhoto,
    name,
    icon,
    subtitle,
    duration,
    level,
    maxPeople,
    price,
    customPriceLabels,
    description,
    text,
    galleryTitle,
    widePictures,
    pictures,
    largepics,
    instructions,
  } = workshop

  const image = `https:${picture.fixed.src}`
  const url = `${data.site.siteMetadata.siteUrl}/ateliers/${slug}/`

  const structured: WithContext<Course> = {
    "@context": "https://schema.org",
    "@type": "Course",
    name,
    description: subtitle,
    image,
    url,
    contentLocation: {
      "@type": "Place",
      name: "Viaduc des Arts",
      address: "13 avenue Daumesnil 75012 Paris",
    },
    provider: {
      "@type": "Organization",
      name: data.site.siteMetadata.title,
      email: EMAIL_ADDRESS,
    },
  }

  if (duration.toLowerCase().includes("h")) {
    const time = parseInt(duration, 10)
    if (time) {
      structured.timeRequired = `PT${time}H`
    }
  }

  return (
    <Layout image={image} title={name} description={subtitle} url={url}>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(structured) }} />
      <Splash>
        <Parallax desktop={photo} mobile={mobilePhoto} />
      </Splash>
      <Content>
        <Section>
          <SubtitleWithIcon>
            {name.toUpperCase()} <span dangerouslySetInnerHTML={{ __html: icon.svg.content }} />
          </SubtitleWithIcon>
          <Title ref={scrollToRef} id="title">
            <span>{subtitle}</span>
          </Title>
          <Features>
            <Feature>
              <TimeIcon />
              <FeatCaption>{duration}</FeatCaption>
            </Feature>
            <Feature>
              <LevelIcon />
              <FeatCaption>{level}</FeatCaption>
            </Feature>
            <Feature>
              <ParticipantIcon />
              <FeatCaption>{maxPeople} pers. max.</FeatCaption>
            </Feature>
            <Feature>
              <MoneyIcon />
              <FeatCaption>{customPriceLabels ? customPriceLabels.join("\n") : formatMoney(price)}</FeatCaption>
            </Feature>
          </Features>
          <Description as="div">
            <RichText node={description} />
          </Description>
        </Section>
        <Section>
          <CtaButtons />
        </Section>
        <Section>
          <WorkshopTitle>{name.toUpperCase()}</WorkshopTitle>
          <Text as="div">
            <RichText node={text} />
          </Text>
        </Section>
        {pictures && (
          <Section>
            <Models margin={widePictures}>{galleryTitle}</Models>
            {!widePictures && (
              <Gallery columns={2}>
                {pictures.map((image) => (
                  <figure key={image.id}>
                    <Image file={image} />
                    <figcaption>{image.title}</figcaption>
                    <div>{image.description}</div>
                  </figure>
                ))}
              </Gallery>
            )}
            {widePictures &&
              largepics.map((image) => (
                <figure key={image.id}>
                  <Image file={image} />
                  <ImageCaption>{image.description}</ImageCaption>
                </figure>
              ))}
          </Section>
        )}

        <CtaButtons />

        <Section>
          <FaqLink />
        </Section>

        <InfosContainer>
          <Infos>
            <InfosImage file={data.infos.childImageSharp} alt="" />
            <InfosContent>
              <InfosTitle>Déroulement de l’{name}</InfosTitle>
              <InfosText>
                <RichText node={instructions} />
              </InfosText>
              <FaqLink />
            </InfosContent>
          </Infos>
        </InfosContainer>
        {data.instagram.nodes.length > 0 && (
          <Section>
            <SectionTitle>L’{name} en images</SectionTitle>
            <Text>
              <a
                href={`https://www.instagram.com/explore/tags/${INSTAGRAM}/`}
                target="_blank"
                rel="noopener"
                className="tag"
              >
                #{INSTAGRAM}
              </a>
              <br />#{tag}
            </Text>
            <Instagram data={data.instagram} />
          </Section>
        )}
      </Content>
    </Layout>
  )
}

export default WorkshopTemplate

export const query = graphql`
  query($slug: String!, $tag: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    workshop: contentfulWorkshop(slug: { eq: $slug }) {
      picture {
        fixed(width: 550) {
          src
        }
      }
      photo {
        fluid(maxWidth: 1440) {
          ...GatsbyContentfulFluid
        }
      }
      mobilePhoto {
        fluid(maxHeight: 800) {
          ...GatsbyContentfulFluid
        }
      }
      name
      icon {
        svg {
          content
        }
      }
      subtitle
      duration
      level
      maxPeople
      customPriceLabels
      price
      description {
        json
      }
      text {
        json
      }
      galleryTitle
      widePictures
      pictures {
        id: contentful_id
        title
        description
        fluid(maxWidth: 550) {
          ...GatsbyContentfulFluid
        }
      }
      largepics: pictures {
        id: contentful_id
        title
        description
        fluid(maxWidth: 1160) {
          ...GatsbyContentfulFluid
        }
      }
      slug
      formulas {
        id: contentful_id
        name
        prefix
        price
        people
        description
        requiresDescription
      }
      variants
      instructions {
        json
      }
    }
    instagram: allInstagramPost(sort: { fields: [date], order: DESC }, filter: { _tags: { eq: $tag } }, limit: 18) {
      nodes {
        id
        link
        image {
          childImageSharp {
            fluid(maxWidth: 350) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        caption
      }
    }
    infos: file(relativePath: { eq: "infos.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, traceSVG: { background: "#E10F14", color: "#FFFFFF" }) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
